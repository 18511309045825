:root{
    --font-family-base : Mulish;
    --font-family-titles: Montserrat-Bold;
    --font-family-subtitles: Montserrat-SemiBold;
    --font-family-description: Montserrat-ExtraLight;
}

@font-face{
    font-family: Montserrat-Bold;
    src: url(../../assets/fonts/Montserrat/Montserrat-Bold.ttf)
}

@font-face{
    font-family: Montserrat-SemiBold;
    src: url(../../assets/fonts/Montserrat/Montserrat-SemiBold.ttf)
}

@font-face{
    font-family: Montserrat-ExtraLight;
    src: url(../../assets/fonts/Montserrat/Montserrat-ExtraLight.ttf)
}

@font-face{
    font-family: Mulish;
    src: url(../../assets/fonts/Mulish/Mulish-ExtraLight.ttf)
}

@font-face{
    font-family: Mulish-SemiBold;
    src: url(../../assets/fonts/Mulish/Mulish-SemiBold.ttf)
}

@font-face {
    font-family: MontserratAlt-Bold;
    src: url(../../assets/fonts/MontserratAlt/MontserratAlternates-Bold.ttf);
}

@font-face {
    font-family: MontserratAlt-SemiBold;
    src: url(../../assets/fonts/MontserratAlt/MontserratAlternates-SemiBold.ttf);
}

$color-mint: #aad8d0;
$color-green-blue: #107e7d;
$color-deep-blue: #094b7f;
$color-light-orange: #f1861f;
$color-b-orange: #e43f12;
$color-orange: #bb4d00;
$color-pink: #aa1355;
$color-purple: #601547;


