$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-caret-down: "\ea67";
$icon-caret-left: "\ea68";
$icon-caret-right: "\ea69";
$icon-caret-up: "\ea6a";
$icon-rounded-down: "\ea99";
$icon-rounded-left: "\ea9d";
$icon-rounded-right: "\eaa0";
$icon-rounded-up: "\eaa1";
$icon-simple-left: "\eab5";
$icon-simple-right: "\eab8";
$icon-telegram: "\ed75";
$icon-rounded-down1: "\ea9a";
$icon-rounded-left1: "\ea9e";
$icon-rounded-right1: "\eaa2";
$icon-rounded-up1: "\eaa3";
$icon-paper-plane: "\efb4";
$icon-cross-sign: "\e91a";
$icon-ps: "\e912";
$icon-ai: "\e913";
$icon-id: "\e914";
$icon-lr: "\e915";
$icon-gitlab: "\e900";
$icon-copy: "\e916";
$icon-mail: "\e917";
$icon-ing: "\e918";
$icon-in: "\e919";
$icon-quote-right: "\e910";
$icon-quote-left: "\e911";
$icon-twitter: "\e901";
$icon-whatsapp: "\e902";
$icon-rocket: "\e903";
$icon-planet: "\e904";
$icon-angular: "\e905";
$icon-vuejs: "\e906";
$icon-git: "\e907";
$icon-sass: "\e908";
$icon-bxl-nodejs: "\e909";
$icon-react: "\e90a";
$icon-up: "\e90b";
$icon-down: "\e90c";
$icon-chevron-up: "\e90d";
$icon-chevron-down: "\e90e";
$icon-menu: "\e90f";

