@import '../../styles/settings/mixings.settings';
@import '../../styles/settings/variables.settings';

.porfolio-creative{
    @include flex(center);
    flex-direction: column;
    
    @include desktopView {
        flex-direction: unset;
        justify-content: space-between;
        width: 90%;
    }

}

.creative {
    width: 100%;
    margin: 1rem 1rem 5rem 1rem;

    &-title {
        text-align: center;
        font-size: 22px;
        letter-spacing: 0.7px;
        color: $color-b-orange;
        padding-bottom: 1.5rem;
    }

    
    & figure {
        width: 80%;
        margin: 0 auto;

    }
    

    @include tabletView {
        &-grid {
            @include flex(space-between);
        }

        & figure {
            width: 180px;
            height: 350px;
            margin: 1rem 0.5rem;
            border-radius:8px;
            overflow: hidden;
            border-radius: 10px;

            & img {
                width: 100%;
                height: 350px;
                object-fit: cover;
                transform: scale(1);
                transition: all 0.3s ease-in-out;
                &:hover {
                    transform: scale(1.05);
                }
            }
        }
        
    }

    @include desktopView {
        width: 50%;
        & figure {
            width: 32%;
        }

    }
}

