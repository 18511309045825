@import '../../../styles/settings/mixings.settings';
@import '../../../styles/settings/variables.settings';


.gallery {
    padding-top: 56px;
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}
  
.img-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
  overflow: hidden;
  position: relative;

  &:hover .content-overlay {
    opacity: 1;
    border-radius:8px;
  }
}
.content-overlay {
  background: rgba(0,0,0,0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  @include flex(center);
  flex-direction: column;
  & p, h3 {
    text-align: center;
    color: white;
    padding: 0 2rem;
  }
  & h3 {
    font-size: 20px;
    @include underlineTitle(230px, $color-light-orange);

    

  }
}

  

  
.img-grid {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius:8px;

}

#carousel-img {
  height: 80vh;
  max-width: 90vw;
  object-fit: cover;

}

#carousel {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.carousel-btn {
  cursor: pointer;   
  outline: none;
  border: 1.8px solid $color-light-orange;
  background-color: transparent;
  border-radius: 50px;
  height: 60px;
  width: 60px;
  margin: 0 5rem;

}
.icon-caret-left, .icon-caret-right {
  color: white;
  font-size: 20px;
}



.img-bigger {
  position: absolute;
  top: 6.5rem;
  height: 100vh;
  margin: 0 auto;
  z-index: 5;
}
