@import '../../styles/settings/variables.settings';
@import '../../styles/settings/mixings.settings';



.intro {
  position: relative;
  height: 100vh;

  &-title {
    color: mix(#fff, $color-b-orange, 5%);
    font-size: calc(1rem + 10vw);  
    font-weight: 900;
    text-align: center;
    text-shadow: 
      0.005em 0.005em 0 mix(#fff, $color-b-orange, 60%),
      0.01em 0.01em 0 mix(#fff, $color-b-orange, 62%), 
      0.015em 0.015em mix(#fff,$color-b-orange, 64%), 
      0.02em 0.02em 0 mix(#fff, $color-b-orange, 66%), 
      0.025em 0.025em 0 mix(#fff, $color-b-orange, 68%),
      0.03em 0.03em 0 mix(#fff, $color-b-orange, 70%),
      0.035em 0.035em 0 mix(#fff, $color-b-orange, 72%);
  }
    &-txt {
        position: absolute;
        top: 48%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 200;
        p {
            font-size: 2rem;
            text-align: center;
            color: $color-purple;
            line-height: .9;
        }
        
    }
}

.animation {
  position: absolute;
  bottom: 1.563rem;
  z-index: 10;
  width: 5rem;
  margin: 0 auto;
}

.circle {
  position: absolute;
  top: -410px;
  left: -1200px;
  background-color: white;
  border-radius: 50%;
  z-index: 5;
  height: 130vh;
  width: 1650px;
}

.waves {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 200px;
  transition: 700ms;
  z-index: -1;
}

.waves::before,
.waves::after {
  content: '';
  position: absolute;
  width: 300vw;
  height: 300vw;
  top: -70vw;
  left: 50%;
  transform: translate(-50%, -75%);
}

.waves::before {
  border-radius: 44%;
  background: rgba(16, 126, 125, 0.6);
  animation: waves 8s linear infinite;
}

.waves::after {
  border-radius: 44%;
  background: rgba(170, 216, 208, 0.5);
  animation: waves 15s linear infinite;
}

@include tabletView {

  .circle {
    top: -50%;
    left: -750px;
    height: 150vh;
    width: 1300px;
  }

  .animation {
    top: 70%;
    left: 3%;
    bottom: 10%;
    width: 7rem;
  }
  
}


@include desktopView {

  .circle {
    top: -40%;
    left: -650px;
    width: 1600px;
  }
}

@include desktopLargeView {
  .intro {
    height: 105vh;
  }
}






  
  @keyframes waves {
    0% {
      transform: translate(-50%, -75%) rotate(0deg);
    }
    
    100% {
      transform: translate(-50%, -75%) rotate(360deg);
    }
  }