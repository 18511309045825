@import "../../styles/settings/mixings.settings";
@import "../../styles/settings/variables.settings";

//Home
.about-figure {
  &-img_container {
    display: inline-block;
    position: relative;
    height: 300px;
    width: 300px;
    border-radius: 50%;
    overflow: hidden;
    margin-top: 1rem;

    & img {
      object-fit: cover;
      height: 100%;
      width: auto;
    }
    @include tabletView {
      width: 37rem;
      height: 37rem;
      & img {
        margin-top: 0;
      }
    }
  }

  &_txt {
    font-size: 3rem;
    text-align: center;
    padding: 2rem 0 0;
    margin: 0;
    @include titleSection($color-purple, 6);
  }
}

// Description
.about-description {
  padding: 0 2rem 4rem;
}

//Quotes

.icon-quote-left,
.icon-quote-right {
  color: $color-light-orange;
  position: absolute;
  opacity: 0.5;
  z-index: 3;
  font-size: 18vh;
}

.icon-quote-left {
  top: 8%;
  left: 1.2%;
}

.icon-quote-right {
  bottom: 0;
  right: 1.2%;
}

@include desktopView {
  .about-figure {
    margin: auto 0;
    position: relative;

    &-img_container {
      width: 30rem;
      height: 30rem;
    }

    &_txt {
      font-size: 4rem;
      position: absolute;
      z-index: 2;
      top: 4rem;
      left: 0;
      text-align: center;
    }
  }
  .about-description {
    margin: 0 auto;
    font-size: 1.3vw;
    text-align: center;
  }
}

@include desktopLargeView {
  .about-description {
    padding: 3rem;
  }
  .icon-quote-left,
  .icon-quote-right {
    font-size: 20vh;
  }
  .icon-quote-left {
    top: 2%;
    left: 0;
  }
  .icon-quote-right {
    bottom: 4%;
    right: 0;
  }
}

@include desktopXLView {
  .about-figure {
    &-img_container {
      width: 37rem;
      height: 37rem;
    }
  }
}
