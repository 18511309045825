@import "../settings/variables.settings";

.section {
  &-title {
    text-align: center;
    padding: 0 1.2rem;
    -webkit-animation: tracking-in-expand 1s
      cubic-bezier(0.68, -0.55, 0.265, 1.55);
    animation: tracking-in-expand 1s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    @include titleSection($color-purple, 7.5);
    @include tabletView {
      font-size: 3rem;
      padding-bottom: 2.5rem;
    }
  }
  &-subtitle {
    margin-top: 1.5rem;
  }
}

.technologies {
  @include flex(center);
  flex-wrap: wrap;
  max-width: 480px;
  height: 10vh;
  padding: 2rem 0;

  h4 {
    margin-block-start: 4em;
    margin-block-end: 2em;
  }

  span {
    font-size: 16px;
    font-weight: 500;

    &:hover {
      color: $color-b-orange;
    }
  }
}

.project {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-title {
    text-align: center;
    font-size: 1.3rem;
    padding-top: 1.5rem;
  }

  &-description,
  &-technologies {
    text-align: center;
    padding: 0;
  }

  &-links {
    align-items: center;
    justify-content: center;
    list-style: none;
    & ul {
      @include flex(space-evenly);
      & li {
        padding: 0 1rem;
      }
    }
  }
  @include tabletView {
    flex-direction: unset;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    &-div {
      width: 100%;
      height: 100%;
      padding: 1rem 0;
      @include flex(center);
      flex-direction: column;
    }
  }
}

.links {
  font-family: Mulish-SemiBold;
  color: $color-purple;
  letter-spacing: 0.7px;
  display: flex;
  justify-content: center;
  align-items: center;
  & span {
    font-size: 24px;
  }
  @include tabletView {
    color: white;
  }
}

@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
